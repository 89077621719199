import { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GridLoader } from "react-spinners";
import {loadBooking, makePayment, setTime} from '../../services/';
import styles from './styles.module.scss';
import logoDark from "../../images/logo-main.png";
import checkIcon from '../../images/check-icon.png';
import {TextInput} from '../../components/TextInput/index.js';

const TIMES = [
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00'
]

const MONTHS = [
  {name:'January',short:"Jan",color:'#2A7EFF'},
  {name:'February',short:"Feb",color:'#00E976'},
  {name:'March',short:"Mar",color:'#85FF15'},
  {name:'April',short:"Apr",color:'#DF0051'},
  {name:'May',short:"May",color:'#FF6235'},
  {name:'June',short:"Jun",color:'#001994'},
  {name:'July',short:"Jul",color:'#35D4FF'},
  {name:'August',short:"Aug",color:'#7100D4'},
  {name:'September',short:"Sept",color:'#00554F'},
  {name:'October',short:"Oct",color:'#C02AFF'},
  {name:'November',short:"Nov",color:'#DF0000'},
  {name:'December',short:"Dec",color:'#986AFF'},
]

const SHORT_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]


const STATUS_LIST = [
  'Pending',
  'Confirmed',
  'Cancelled'
]

const getDate = (date, noTime = false)=>{
  let newDate = new Date(date);
  return newDate.getDate()+" "+(MONTHS[newDate.getMonth()].short)+" "+newDate.getFullYear()
}
export const SetTime = () => {
  const navigate = useNavigate();

  const getDateTime = (date, noTime = false)=>{
    let newDate = new Date(date);
    return newDate.getDate()+"/"+(newDate.getMonth()+1)+"/"+newDate.getFullYear()+" "+newDate.getHours()+":"+(newDate.getMinutes() < 10 ? '0' : '')+(newDate.getMinutes())
  }

  const [booking, setBooking] = useState(null)
  const [availableTimeslots, setAvailableTimeslots] = useState([])
  const [clinics, setClinics] = useState([])
  const [clinic, setClinic] = useState(null)
  const [newBookingTimeslot, setNewBookingTimeslot] = useState(null)
  const [newBookingDate, setNewBookingDate] = useState(null)
  const [medicName, setMedicName] = useState('')
  const [loading, setLoading] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams();
  let lastClick = 0

  const init = async()=>{
    const code = searchParams.get('c');

      const loadedBookingsData = await loadBooking({code})
      if(loadedBookingsData.data.success){
        console.log(loadedBookingsData.data.booking)
        setBooking(loadedBookingsData.data.booking);
        setMedicName(loadedBookingsData.data.medicName)
        setAvailableTimeslots(loadedBookingsData.data.availableTimeslots)
        setClinics(loadedBookingsData.data.clinics)
      }else{
        alert("Error getting your booking.");
      }

      setLoading(false);
  }


  useEffect(()=>{
    init();
  },[]);

  const submitDetails = async()=>{
    if(lastClick && ((new Date().getTime() - lastClick) < 3000)){
      return
    }

    lastClick = new Date().getTime();

    setLoading(true)

    const loadedPaymentData = await setTime({bookingId:booking.id,timeslotId:newBookingTimeslot})

    if(loadedPaymentData.data.success){
      setBooking(loadedPaymentData.data.booking)
    }else{
      alert("Error. Please try again.");
    }

    setLoading(false)
  }

  if(loading){
    return(
      <div className="global-loading-page">
        <div className="global-loader">
          <GridLoader  size={30} color={"#191919"} />
        </div>
      </div>
    )
  }





    return(
      <div className="global-container">
        <div className={styles.homeContentContainerMain}>

          <div className={styles.homeDashboardContentMain}>

            <div className="global-header">
              <div className="global-header-profile">
                <div className="global-header-profile-text">Rumu Appointment</div>
              </div>


              <div className="global-header-buttons">
              </div>
            </div>

            <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
              <div className={"global-dashboard-row"}>
                <div className={styles.appointment}>
                  {
                    (booking)?
                      <div>
                        <img src={logoDark} className={styles.logo} />
                        <div className={styles.appointmentHeader}>Appointment Details</div>
                        <table className={styles.appointmentDetails}>
                          <tr>
                            <td>Name</td>
                            <td>{booking['m5_patient']['name']}</td>
                          </tr>

                          <tr>
                            <td>Medic Name</td>
                            <td>{medicName}</td>
                          </tr>
                        </table>
                      </div>
                    :
                    <></>
                  }
                </div>

                <div className={styles.appointmentPayment}>
                  <div className={styles.appointmentHeader}>Set Appointment</div>
                  <br/>
                  {
                    (booking['m5_timeslot'])?
                      <div className={styles.paymentComplete}>
                        <img src={checkIcon} className={styles.paymentCompleteIcon} />
                        <br/>
                        <div className={styles.paymentCompleteText}>
                          Appointment Set! <br/><br/>
                          Date: {getDateTime(booking['m5_timeslot']['startTime'])} <br/><br/>
                          Clinic: {booking['m5_timeslot']?.['clinicId']}
                        </div>
                      </div>
                    :
                    <>
                      <TextInput placeholder="Clinic"  type="clinics-dropdown" options={clinics} onChange={(clinic)=>{
                                            setClinic(clinic)
                                          }} initialValue={''}/><br/><br/>
                      <TextInput placeholder="Date Filter" highlightedDates={availableTimeslots.filter((t)=>{ return (t.clinicId == clinic)}).map((time)=> new Date(time.startTime))} type={'search-date'} onChange={(e)=>{
  
                        let date = new Date(e)
                        setNewBookingDate(date)
  
                      }} initialValue={new Date()} style={{marginLeft:'24px'}} />
                      <TextInput placeholder="Timeslots"  type="timeslots-dropdown" options={availableTimeslots.filter((t)=>{ return (t.clinicId == clinic) && (getDate(t.startTime) == getDate(newBookingDate)) })} onChange={(timeslot)=>{setNewBookingTimeslot(timeslot)}} initialValue={''}/>
                      <br/><br/><br/>
                      <div className={styles.submitButton} onClick={(e)=>{submitDetails()}}>
                        Submit
                      </div>
                    </>
                  }

                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    )



}
